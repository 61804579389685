import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session12/bitter-reach-page-banner-session-12.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import cover from "../../../../src/assets/forbidden-lands/session12/fbl-session-12-cover.webp";
import cloget from "../../../../src/assets/forbidden-lands/session12/cloget-the-goblin.webp";
import contest from "../../../../src/assets/forbidden-lands/session12/fishing-contest.webp";
import ingmarg from "../../../../src/assets/forbidden-lands/session12/ingmar-portrait.webp";
import wurda from "../../../../src/assets/forbidden-lands/session12/wurda-portrait.webp";
import jura from "../../../../src/assets/forbidden-lands/session12/wurda-jura-token.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession12 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Awakening Day Festival",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={cover}
            caption="Awakening Day"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Northfall is alive with the bustle of furriers, traders, and
                hawkers for the Awakening Day festival. Wurda performs the dance
                of Jura the Giantess, Ingmarg announces a fishing contest, and
                Hills sits in a jail cell for harboring Roar, the wayward Romeo
                of the fisherman camp."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The party this session is: Ibax, Lobo, Blisster, and Tulok Fook.
          <br />
          <br />
          <h2>Fishing Contest</h2>
          The party, joined by Biliq and Berry Wight, gather with the crowd in
          the town square near Cuss & Dang's tavern.{" "}
          <span style={{ color: "burlywood" }}>
            Julla the Elder and Marshall Volle are on a makeshift stage
          </span>
          . Towering over the stage are two huge wooden pillars with a sign
          between them. The sign is covered with a blue cloth. A blackguard of
          Ingmarg's sits on each pillar ready to unveil the sign when signalled.
          <br />
          <br />
          Julla the Elder speaks:
          <br />
          <i>
            <span style={{ color: "lightgreen" }}>
              <strong>Wyrm's Fist</strong> from Alderland has been to the north
              to the <strong>Sea of Ice</strong>
              and <strong>World's End</strong>. She is slated to be back in
              Northfall a month from now near the end of springrise.{" "}
              <strong>Capt Baraggor</strong> assures us that rumors of the blood
              mist disappearing in Ravenland are exaggerated, and that only the
              open sea is viable for travels between the lands; and even then,
              and only for captains that know how to traverse the peril of the
              Silver Coast. But, she and her fleet will ensure that the Bitter
              Reach and Northfall prosper in the days to come!
            </span>
          </i>
          <br />
          <br />
          Then Marshall Volle takes the stage and gestures for quiet. The rowdy
          throng of furriers and traders jostle and hush. He says,{" "}
          <span style={{ color: "lightgreen" }}>
            "This year will see us celebrating the biggest and best Awakening
            Day festival the Bitter Reach has ever known. And thanks to a band
            of adventurers, it will also be the safest!"
          </span>
          <br />
          <br />
          He beckons the party onto the stage. He explains that they captured
          Turold Blood-Eye and offers the bounty. Ibak pushes Tulok forward and
          Tulok accepts the coin pouch. Tulok is a popular choice since he is an
          Ailander and the crowd cheers. Tulok promises they will protect and
          <span style={{ color: "lightgreen" }}>
            "serve this community."
          </span>{" "}
          Marshall Volle asks if the party has a name for their outfit. The
          players confer and hencefore, as announced in Northfall on Awakening
          Day of 1165 they shall be known as:
          <br />
          <br />
          <ImageComponent
            image={contest}
            caption="Fishing Contest"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Ingmarg the Plump sponsers a fishing contest this Awakening Day. It has 100sp reward and a small boat, and he dangles the legend of the Suken City before the crowd."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <h3>
            <span style={{ color: "crimson" }}>
              Brothers of the Bitter Reach
            </span>
          </h3>
          As time goes forward, the party will sometimes called Burr by
          non-human kin or "The Brothers" by humans and elves.
          <br />
          <br />
          Marshall Volle has Tulok introduce each PC. This is the party's chance
          to take advantage of their notoriety and stage time in front of the
          largest crowd in the biggest settlement of the Bitter Reach. The GM
          explains this is the time to give PCs each a nickname or tag line if
          they want. Tulok introductions are as follows:
          <br />
          <br />
          <ul>
            <li>
              <strong>
                <span style={{ color: "burlywood" }}>
                  Tulok Fook, the Frozen Fist of the North
                </span>
                .
              </strong>
            </li>
            <li>
              <strong>
                <span style={{ color: "burlywood" }}>Ibak the Wanderer</span>
              </strong>
              .{" "}
              <i>
                <span style={{ color: "lightgreen" }}>
                  He wanders the Bitter Reach from corner to corner and knows
                  more than any other kin.
                </span>
              </i>
            </li>
            <li>
              Lobo (Tulok pauses, and then Ibax shouts out "
              <strong>
                <span style={{ color: "burlywood" }}>Lobo the One Leg</span>
              </strong>
              !"):{" "}
              <i>
                <span style={{ color: "lightgreen" }}>
                  His strength is known across the land by all peoples of one
                  leg, two legs, or even three legs.
                </span>
              </i>{" "}
              A rowdy trapper grabs his crotch and barks,{" "}
              <span style={{ color: "lightgreen" }}>
                "Aye, and I got three legs!"
              </span>
            </li>
            <li>
              <strong>
                <span style={{ color: "burlywood" }}>Blisster the Dark</span>
              </strong>
              : <i>master of the hidden secrets of the land</i>.
            </li>
            <li>Rog-Golar: tbd next session</li>
            <li>Stilak: tbd next session</li>
          </ul>
          <strong>Each PC gets +1 reputation.</strong>
          <br />
          <br />
          Marshall Volle then announces Ingmarg the Plump. Ingmarg approaches
          the stage with a stately grace, four guards around him like the outer
          pips on a five on a six-sided die. He smiles, his smooth skin
          glistening with oil, the only imperfection the ritual slanted scars on
          his cheeks; a vanity for an Ailander, but a serious one.
          <br />
          <br />
          Ingmarg is a large man, always dressed in expensive fur clothes,
          fingers adorned with rings made of amber, whale bone, silver, and
          gold. He is jovial and always tries to make a good deal out of every
          meeting. He aims to earn ten times his stake in any deal, and this is
          how he earned the reputation as the greediest peddler on the Silver
          Coast.
          <br />
          <br />
          <ImageComponent
            image={ingmarg}
            caption="Ingmarg the Plump"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Ingmarg is a large man, always dressed in expensive fur clothes, fingers adorned with rings made of amber, whale bone, silver, and gold. He is jovial and always tries to make a good deal out of every meeting. He aims to earn ten times his stake in any deal, and this is how he earned the reputation as the greediest peddler on the Silver Coast. The merchant is a gourmet who is fond of fine wines and exotic food; uncommon items in the frigid, northern land he calls home."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The trader takes the state to cheers from the gathered assembly and
          speaks:
          <i>
            <span style={{ color: "lightgreen" }}>
              "Aukannik (oh-kah-nick) friends and travellers
            </span>
          </i>
          , says Ingmarg as he gestures toward the fire barrels on each street
          corner.
          <br />
          <i>
            <span style={{ color: "lightgreen" }}>
              "Become warm! <strong>Aukannik</strong>! The council in their
              infinite wisdom, and with the wise guidance of Julla, have agreed
              to let Ingmarg sponsor the greatest Awakening Day contest in
              Northfall history.
              <br />
              <strong>A fishing contest!</strong>
              <br />
              Who can catch the largest pikebeast? 100 silver to the winning
              vessel. There are those who say the largests beast will surely be
              near the <strong>Sunken City</strong>. But it the city a myth and
              a distraction to the true fisherman?
            </span>
          </i>
          <br />
          <br />
          <i>
            Khurkan and his chopping blocks on the black beach are ready! Ready
            for fishermen from Trottersmark, from Ashenmark, even those beyond
            the Keld and Ised rivers from the Barren Hills or the Alter
            Mountains. And all month long the crowds will be gathered to watch
            the incoming ships and to say:{" "}
            <strong>Eee-lah who-yot see-ah-vai-et!!</strong> For the cheechakos
            from alderland that means <strong>"what a catch!"</strong>
          </i>
          <br />
          Ingmarg laughs, a rough chunky laugh that sounds like a sealskin boat
          being dragged over a rocky beach.
          <br />
          <i>
            We have a <strong>sign-up pavilion</strong> near the trading post
            where you can enter the contest and learn about The Sunken City if
            you dare. And now . . . the centerpiece of the celebration; even
            bigger than this magnificent sign . . . and for the first time set
            to music from Alderland performed by <strong>Farquah</strong>, I
            present to you, the story of Jura the Winter Giantess!"
          </i>
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
  ];
  const section2Data = [
    {
      title: "Jura the One-Eyed Winter Giantess",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={wurda}
            caption="Wurda"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Old Wurda is a mystery.

Some say old Wurda is an Ailander; others say she is from Alderland or Aslene. There are many rumors about the ancient crone such as: she is an ostrasized Raven Sister from Ravenland, cast out for purposefully letting an infant die during childbirth. She is the daughter of Jura the Winter Giantess which is why she plays in the Awakening Day festivities every year. She is a demon sowing discord throughout the land. She is an avatar of Wyrm dedicated to protecting the Bitter Reach and those who make it their home. She is a shapechanger with no love for any but herself."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          An older woman steps into the square and onto the stage. She seems
          elderly but strong; elemental. Her yellowed eyes look out upon the
          crowd. Biliq and Berry both mutter "Wurda" under their breath. Then
          Wurda's eyes roll back in her head, white as a blizzard. The crowd
          instinctively steps back.
          <br />
          <br />
          Wurda begins chanting and dancing, shaking a necklace of small bones.
          She waves toward the sky beckoning. A large raven suddenly lands on
          her shoulder. Suddenly, there is a rush of blinding snow. Wurda is
          transformed into an 8 feet tall giantes, Jura the Giantess, as the
          raven flys away into the darkening winter sky, and the band begins to
          play.
          <br />
          <br />
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/CqwrwwOzVcQ?si=FLNsaGpoatoJ9tRR&amp;start=21"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <br />
          <br />A stout ailander woman with the band begins to recite{" "}
          <span style={{ color: "lightblue" }}>
            <strong>The Poetic Saga of Jura the Giantess</strong>
          </span>
          :
          <br />
          <ImageComponent
            image={jura}
            caption="Jura the Giantess"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Jura, giantess of the north,
<br/>
Gathers wood for the winter long.
<br/>
She thunders forth filling her screel.
<br/>
Twas cold forever until this song.
<br/>
With single eye and freezing staff,
<br/>
She wishes snow to everlast.
<br/>
But we here on Awakening Day,
<br/>
Drink beer and pray Jura flys away!
 <br/>
<br/>
Rodenvale will thaw tonight,
<br/>
Jura's stick in mouth whilst in flight,
<br/>
Beating the air with wings of ice,
<br/>
She is fleeing! We sing this night!
<br/>
The Bitter Reach one day be sweet,
<br/>
This we know with keen insight.
<br/>
Until Jura is full begone,
<br/>
Drink more and make merry this night!"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <i>
            <span style={{ color: "lightgreen" }}>
              "Jura, giantess of the north, / Gathers wood for the winter long.
              <br />
              She thunders forth filling her screel. / Twas cold forever until
              this song.
              <br />
              With single eye and freezing staff, / She wishes snow to everlast.
              <br />
              But we here on Awakening Day, / Drink beer and pray Jura flys
              away!
              <br />
              <br />
              Rodenvale will thaw tonight, / Jura's stick in mouth whilst in
              flight,
              <br />
              Beating the air with wings of ice, / She is fleeing! We sing this
              night!
              <br />
              The Bitter Reach one day be sweet, / This we know with keen
              insight.
              <br />
              Until Jura is full begone, / Drink more and make merry this
              night!"
            </span>
          </i>
          <br />
          <br />
          Jura crouches and prowls at the edge of the crowd, making false grabs
          to scare the children and some of the adults. As the recitation
          continues, the crowd joins in where the text calls for cheering or
          shouting. Wurda as Jura begins to look doubtful as the crowd cheers
          and Ingmarg's black guard toss out candy to the crowd. Finally, on the
          words "see is fleeing" Wurda transforms into an owl and, screeching,
          flees the town square. The owl flies staight at the party.{" "}
          <span style={{ color: "lightblue" }}>
            Tulok Fook stands tall as the owl dive bombs and finds a beautiful,
            intact owl feather on his face
          </span>{" "}
          as the show ends.
          <br />
          <br />
          The party realizes trading is done during the day when everyone is
          sober and their is better light; and so, they join the festivities as
          follows:
          <ImageComponent
            image={cloget}
            caption="Cloget"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Cloget is a goblin from Waziya (Cold Wind That Blows) clan that live across Fallowmoor near the Morma Glacier along Garm's Edge.
He and follow goblins attend the Awakening Day festival in Northfall every year.
He is a champion at Knuckle Hop."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ul>
            <li>
              <span style={{ color: "burlywood" }}>
                Tulok Fook gambles on a game of Kingdom of the Winter Elves
              </span>
              . He wins two silver and hears the legend:{" "}
              <span style={{ color: "lightblue" }}>
                <strong>Palace of the Winter King</strong>
              </span>
              .
            </li>
            <li>
              <span style={{ color: "burlywood" }}>
                Blister and Lobo challenge Cloget the Waziya Clan goblin to a
                knuckle hop competition
              </span>
              ; a contest which Cloget is a known champion, his routund belly
              helping him and giving him an extra dice to roll.
            </li>
            <ul>
              <li>
                The{" "}
                <a href="./forbidden-lands-contests" target="_blank">
                  knuckle hop is a brutal endurance contest.
                </a>
                .
              </li>
              <li>
                In a wild surprise to an ever increasing, fascinated crowd,{" "}
                <span style={{ color: "burlywood" }}>
                  Lobo the One Leg wins the contest
                </span>{" "}
                going 165 feet with Cloget in 2nd place (129 feet) and Blisster
                in 3rd (58 feet).
              </li>
              <li>
                Lobo went until he broke. While sitting broken by wits waiting
                for Cloget to finish, Lobo is approached by Iona, the love of
                Roar and cause of the commotion between the whalers and
                fishermen camps. Iona She heals Lobo by singing an ailander
                lullaby. Many in the crowd are agog at the sight of a beautiful
                ailander girl helping a wolfkin.{" "}
                <span style={{ color: "burlywood" }}>
                  Lobo gives Iona the carved bone wolf statue he made
                </span>{" "}
                at Cuss & Dang's tavern when he and his friends first stepped
                foot off of Wyrm's Fist into Northfall.
              </li>
              <li>
                The crowd is riled up after the race ends with{" "}
                <span style={{ color: "burlywood" }}>
                  arguments about allowing a wolfkin to participate
                </span>
                . An{" "}
                <span style={{ color: "burlywood" }}>
                  orc named Zlapdud is the loudest protestor
                </span>{" "}
                saying that wolfin should not be allowed in knuckle hop because
                <span style={{ color: "lightgreen" }}>
                  "wolfkin are simple dogs that run around all day on all fours
                  like a beast."
                </span>{" "}
                A tall, think Alderlander with a handlebar moustache named{" "}
                <strong>Fashid</strong> is nervously collecting winning and
                paying out on lost bets as as the argument heats up.
              </li>

              <li>
                The contention has Blisster proclaiming above the shouts,{" "}
                <span style={{ color: "lightgreen" }}>
                  "There was no rule against it. You live, you learn."
                </span>{" "}
                Tempers rise and then Ibax steps in and speaks:{" "}
                <span style={{ color: "lightgreen" }}>
                  "No one said Lobo the One Leg could not participate. You saw
                  his missing leg and you thought it would be an easy win, admit
                  it."{" "}
                </span>
              </li>
              <li>
                Zlapdud loses momentum in the argument thanks to Ibax's logic
                and crowd appeal. Zlapdud's mate/girlfriend Kulgha distracts the
                brute with sex appeal and appeals to go elsewhere and party. She
                smiles at Ibax who notices that the rumors that female orcs can
                be beautiful and nearly pass for human are true.{" "}
                <span style={{ color: "burlywood" }}>
                  Fashid pays out 30 silver to Lobo!
                </span>
              </li>
            </ul>
            <li>
              <span style={{ color: "burlywood" }}>
                Blisster goes on a bender with Cloget and his goblin buddies
              </span>
              . He awakens the next day with a hangover but no worse for the
              wear. But now he feels as though he is nearly family with the
              wolf-riding Waziya Goblin Clan. He knows that if he is ever near
              Garm's Edge at the cusp of the Morma Glacier, he will be welcomed
              by the goblins there.
            </li>
            <li>
              The next day{" "}
              <span style={{ color: "burlywood" }}>
                Tulok Fook finds the owl feather from polymorphed Wurda on his
                face
              </span>
              . He does things with the feather. It does not seem to be magical,
              but how did it get on his face while he slept?
            </li>

            <li>
              <span style={{ color: "burlywood" }}>
                The next day is spent training
              </span>
              . Marshall Volle, Cloget, and others in town for the festival are
              available.
              <ul>
                <li>Blisster spends 6 XP to get Lockpicker 2.</li>
                <li>
                  Ibax is holding his XP, hoping to speak with Wurda and learn
                  magic.
                </li>
                <li>
                  Lobo spends 6 xp to learn Melee Charge 2 and another 9 xp to
                  get up to Path of the Blade 3.
                </li>
                <li>
                  Tulok also learn Path of the Blade up to level 3 spending 15
                  xp.
                </li>
              </ul>
            </li>
          </ul>
          <small>Attendees: Blisster, Ibax, Lobo, , Tulok Fook.</small>
          <br />
          Rog-Golar and Stilak had their 10sp added for reward, but they both
          still need +1 reputation, their tagline if any, and training they did
          during the previous day if any.
          <br />
          <small>Ending Datetime: 6am, Springrise 07, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0</li>
            <li>
              Legend: 2.0 (Legend of Ingmard told by Berry Wight, Legend Palace
              of the Winter King told to Tuklok by competitor in Kingdom of the
              Ice Elves game)
            </li>
            <li>Lore: 0.0</li>
            <li>Hex: 0.0</li>
            <li>
              Pride: 1.0 (Lobo and Blisster both use during knuckle hop and both
              successful)
            </li>
            <li>Treasure: 0.0</li>
            <li>Monster: 00.0</li>
            <li>Dark Secret: 0.0</li>
          </ul>
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title
                id="cardTitle-forbidden-lands"
                className="cardTitle-forbidden-lands  "
              >
                Northfall is bustling with furriers, traders, and hawkers for
                the Awakening Day festival. Wurda performs the dance of Jura the
                Giantess, Ingmarg announces a fishing contest, and Hills sits in
                a jail cell for harboring Roar, the wayward Romeo of the
                fisherman.
                <br />
                <br />
                <small>
                  ⇐ Session 11:{" "}
                  <a href="./forbidden-lands-session-11">Panoowa, Ghost Boy</a>{" "}
                </small>
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
              <div>
                <ContentContainer data={section2Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession12;
